<template>
  <div>
    <div v-if="fetching" class="flex justify-center items-center h-[60vh]">
      <spinner class="w-20 h-20" color="oneGreen" />
    </div>

    <div v-else>
      <section class="text-idBlued">
        <h5>SECTION B:</h5>
        <h2>NEXT OF KIN</h2>
      </section>

      <form @submit.prevent="proceed">
        <div>
          <label for="lastName"> Last Name<span class="req">*</span> </label>
          <input
            id="lastName"
            type="text"
            v-uppercase
            class="input"
            v-model="form.lastName"
          />
        </div>

        <div>
          <label for="firstName"> First Name<span class="req">*</span> </label>
          <input
            id="firstName"
            type="text"
            v-uppercase
            v-model="form.firstName"
            class="input"
          />
        </div>

        <div>
          <label for="middleName">
            Middle Name<span class="req">*</span>
          </label>
          <input
            id="middleName"
            type="text"
            v-uppercase
            v-model="form.middleName"
            class="input"
          />
          <!-- required -->
        </div>

        <div>
          <label for="relationship">
            Relationship<span class="req">*</span>
          </label>
          <v-select
            id="relationship"
            label="relationship"
            :reduce="(b) => b.relationship"
            :options="$store.state.staff.relationships"
            v-model="form.relationship"
            placeholder="-- select --"
          ></v-select>
        </div>

        <div>
          <label for="gender"> Gender<span class="req">*</span> </label>
          <v-select
            id="gender"
            label="label"
            :reduce="(b) => b.value"
            :options="genders"
            v-model="form.gender"
            placeholder="-- select --"
          ></v-select>
        </div>

        <div>
          <label for="phone">KIN Phone Number<span class="req">*</span> </label>
          <input
            id="phone"
            type="number"
            v-uppercase
            v-model="form.phone"
            class="input"
            pattern="/\d*$/"
            onKeyPress="if(this.value.length>14) return false;"
            placeholder="enter next of kin phone number"
          />
        </div>

        <div>
          <label for="homeAddress">
            KIN Address<span class="req">*</span>
          </label>
          <input
            id="homeAddress"
            type="text"
            v-uppercase
            v-model="form.address"
            class="input"
          />
        </div>

        <div v-if="!$store.getters['staff/stepStatus'](1)">
          <button :disabled="proceeding || !formReady" type="submit">
            <span>Save and Proceed</span>
            <spinner v-if="proceeding"></spinner>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: "PensionNextofKin",

  data() {
    return {
      proceeding: false,
      fetching: true,
      form: {
        lastName: this.$store.state.staff.nok.lastName,
        firstName: this.$store.state.staff.nok.firstName,
        middleName: this.$store.state.staff.nok.middleName,
        relationship: this.$store.state.staff.nok.relationship,
        gender: this.$store.state.staff.nok.kinGender,
        phone: this.$store.state.staff.nok.kinPhone,
        address: this.$store.state.staff.nok.kinAddress,
      },
      genders: [
        { label: "Female", value: "F" },
        { label: "MALE", value: "M" },
      ],
    };
  },

  computed: {
    step() {
      return this.$store.getters["staff/step"];
    },
    formReady() {
      let isOk = true;
      Object.keys(this.form).forEach((key) => {
        if (!this.form[key]) {
          isOk = false;
        }
      });
      return isOk;
    },
  },

  async created() {
    await this.$store.dispatch("staff/getRelationships");
    this.fetching = false;
  },

  methods: {
    async proceed() {
      if (!this.formReady) {
        this.$swal({
          icon: "error",
          text: "Fill all fiields",
        });
        return;
      }
      try {
        this.proceeding = true;

        const res = await this.$http.patch("/employee/next-of-kin", this.form);

        this.proceeding = false;
        if (!res) {
          return;
        }

        const { data } = res;
        this.$store.commit("staff/updateData", {
          data: data.data,
          key: "nok",
        });

        this.$store.commit("staff/updateVerifyStep", 2);
        this.$store.commit("staff/updateVerifyStep", 3);
        this.$store.commit("staff/updateBStep", 2);

        this.$router.push({ name: "Pension Section C" });
      } catch (error) {
        this.proceeding = false;
      }
    },
  },
};
</script>

<style scoped>
h5 {
  @apply text-xs lg:text-base font-bold uppercase;
}
h2 {
  @apply text-xl lg:text-3pxl uppercase;
}
form {
  @apply bg-litergray rounded-lg p-6 mt-7;
}
form > div:not(:first-child) {
  @apply mt-6;
}
.req {
  @apply text-red-400;
}
form button[type="submit"] {
  @apply w-full bg-oneGreen text-white font-semibold py-2.5 rounded-lg flex justify-center items-center gap-2;
}
input {
  @apply uppercase;
}
</style>
