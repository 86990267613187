<template>
  <div class="">
    <HorizontalNav :navs="navs" />

    <section class="mt-4">
      <router-view></router-view>
    </section>
  </div>
</template>

<script>
import HorizontalNav from "@/components/staff/verification/HorizontalNav.vue";
export default {
  name: "Pensionier",
  data() {
    return {
      navs: [
        { title: "Bio-Data", link: "Pension Bio Data" },
        { title: "Document Upload", link: "Pension Document Upload" },
        { title: "BVN Verification", link: "Pension Bvn Verification" },
        {
          title: "Summary",
          link: "Pension Verification Summary",
        },
      ],
    };
  },
  components: {
    HorizontalNav,
  },

  created() {
    // this.$store.dispatch("staff/getProfile");
  },
};
</script>
