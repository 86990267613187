<template>
  <div class="px-4 lg:px-16">
    <section class="rounded bg-red-100 py-4 px-5 text-dkgray text-sm">
      <h3 class="font-extrabold">Instructions:</h3>
      <p class="mt-1">
        Print 2 copies of this form, visit the .... office with the original
        copies of your credentials for final verification
      </p>
    </section>

    <section class="mt-10">
      <VerificationPrintout />
    </section>
  </div>
</template>

<script>
import VerificationPrintout from "@/components/staff/verification/VerificationPrintout.vue";
export default {
  name: "PensionReportSummary",

  components: {
    VerificationPrintout,
  },
};
</script>

<style scoped></style>
