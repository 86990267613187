<template>
  <div>
    <div v-if="fetching" class="flex justify-center items-center h-[60vh]">
      <spinner class="w-20 h-20" color="oneGreen" />
    </div>

    <div v-else>
      <section class="text-idBlued">
        <h5>SECTION D:</h5>
        <h2>Pension Bank Info</h2>
      </section>

      <form @submit.prevent="proceed">
        <fieldset>
          <h4>Salary Account Details</h4>

          <div>
            <label for="bankName"> Bank Name<span class="req">*</span> </label>
            <input
              id="bankName"
              type="text"
              v-model="form1.bankName"
              class="input"
              readonly
            />
          </div>

          <div>
            <label for="accountNo">Account No<span class="req">*</span> </label>
            <input
              id="accountNo"
              type="text"
              v-model="form1.accountNo"
              class="input"
              readonly
            />
          </div>

          <div>
            <label for="accountName">
              Payroll Name<span class="req">*</span>
            </label>
            <input
              id="accountName"
              type="text"
              v-model="form1.accountName"
              class="input"
              readonly
            />
          </div>
        </fieldset>

        <fieldset>
          <h4>Choose your Bank to confirm your details</h4>
          <div>
            <label for="accountNo2"
              >Account No<span class="req">*</span>
            </label>
            <input
              id="accountNo2"
              type="text"
              v-model="form2.accountNo"
              class="input"
              readonly
            />
          </div>

          <div>
            <label for="bankName2">Bank Name<span class="req">*</span> </label>

            <div class="flex justify-between items-baseline gap-3">
              <v-select
                id="bankName2"
                class="w-full"
                label="bankName"
                :reduce="(b) => b.bankCode"
                :options="$store.state.staff.banks"
                v-model="form2.bankCode"
                placeholder="-- select --"
              ></v-select>
            </div>
          </div>

          <div>
            <label for="accountName2">
              Account Name<span class="req">*</span>
            </label>
            <input
              id="accountName2"
              type="text"
              v-model="form2.accountName"
              class="input"
              readonly
            />
          </div>
          <div class="flex justify-between">
            <p class="bankVal" style="">
              Click on this button to validate your account name by choosing
              your bank from the drop down above.
            </p>
            <button
              :disabled="checking || !bankReady"
              class="validateBtn"
              @click="nubanCheck"
              type="button"
            >
              <spinner class="" v-if="checking"></spinner>
              <span>Validate Account</span>
            </button>
          </div>
          <div>
            <p v-if="nameMatch" class="namemmatch">
              {{ nameMatchmessage }}
            </p>
          </div>
          <div v-if="nameMisMatch">
            <p class="namemismatch">
              {{ namemismatchMessage }}
            </p>
          </div>
        </fieldset>

        <fieldset v-if="completeNuban">
          <div v-if="!$store.getters['staff/stepStatus'](4)">
            <button :disabled="proceeding || !nubanReady" type="submit">
              <span>Complete Bio</span>
              <spinner v-if="proceeding"></spinner>
            </button>
          </div>
        </fieldset>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: "PensionBankInfo",

  data() {
    return {
      nameMatchmessage: null,
      namemismatchMessage: null,
      selctedBank: null,
      proceeding: false,
      nameMatch: false,
      nameMisMatch: false,
      completeNuban: false,
      fetching: true,
      checking: false,
      banksListn: this.$store.state.staff.banks,
      form1: {
        bankName: this.$store.state.staff.profile.bankName,
        accountNo: this.$store.state.staff.profile.salaryAccountNumber,
        accountName: this.$store.getters["general/fullName"],
      },
      form2: {
        nin: null,
        bankCode: null,
        accountNo: this.$store.state.staff.profile.salaryAccountNumber,
        accountName: null,
      },
      options: [
        { label: "Option 1", value: "1" },
        { label: "Option 2", value: "2" },
        { label: "Option 3", value: "3" },
        { label: "Option 4", value: "4" },
        { label: "Option 5", value: "5" },
      ],
    };
  },

  async created() {
    await this.$store.dispatch("staff/getBanks");
    this.fetching = false;
  },
  computed: {
    step() {
      return this.$store.getters["staff/step"];
    },
    bankReady() {
      return Boolean(this.form2.bankCode);
    },
    nubanReady() {
      return this.bankReady && Boolean(this.form2.accountName);
    },
  },

  methods: {
    async nubanCheck() {
      if (!this.bankReady) {
        this.$swal({
          icon: "error",
          text: "Select a bank",
        });
        return;
      }
      try {
        this.checking = true;
        const res = await this.$http.patch("/employee/account-details", {
          bankCode: this.form2.bankCode,
        });

        this.checking = false;
        if (!res) {
          return;
        }

        const { data } = res;
        if (!data.data) {
          this.$swal({
            icon: "error",
            text: "Invalid Bank details ",
          });
          return;
        }
        // 100% FAILURE
        if (data.code === "E400") {
          this.nameMisMatch = true;
          this.namemismatchMessage = data.message;
        }
        // 75% FAILURE
        if (data.code === "E200") {
          this.nameMatch = true;
          this.nameMatchmessage = data.message;
        }
        // 100% MATCH
        if (data.code === "S200") {
          this.nameMatch = true;
          this.nameMatchmessage = data.message;
        }
        this.form2.accountName = data.data.accountName;
        this.completeNuban = true;
      } catch (error) {
        this.checking = false;
      }
    },

    async proceed() {
      if (!this.nubanReady) {
        this.$swal({
          icon: "error",
          text: "Fill all fields",
        });
        return;
      }
      try {
        this.proceeding = true;

        const res = await this.$http.post("/employee/complete-bio");

        this.proceeding = false;
        if (!res) {
          return;
        }

        // clear the bioStep and data
        this.$store.commit("staff/updateVerifyStep", 5);
        this.$store.commit("staff/updateBStep", 1);

        this.$swal({
          icon: "success",
          text: "Bio Data completed",
        });

        this.$router.push({ name: "Pension Document Upload" });
      } catch (error) {
        this.proceeding = false;
      }
    },
  },
};
</script>

<style scoped>
.namemmatch {
  color: #1a8103;
  font-family: Mulish;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.namemismatch {
  color: #f00;
  font-family: Mulish;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.bankVal {
  font-family: Mulish;
  font-size: 14px;
  width: 60%;
  font-style: italic;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #8d8d8d;
}

.validateBtn {
  border-radius: 8px;
  background: #1a8103;
  width: auto;
  display: flex;
  height: 40px;
  padding: 0px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #fff;
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

h5 {
  @apply text-xs lg:text-base font-bold uppercase;
}

h2 {
  @apply text-xl lg:text-3pxl uppercase;
}

form {
  @apply mt-7;
}

fieldset {
  @apply bg-litergray rounded-lg p-6 mt-6;
}

fieldset > div:not(:first-child) {
  @apply mt-6;
}

fieldset h4 {
  @apply text-litgray font-medium text-base lg:text-2xl;
}

.req {
  @apply text-red-400;
}

form button[type="submit"] {
  @apply w-full bg-oneGreen text-white font-semibold py-2.5 rounded-lg flex justify-center items-center gap-2;
}

input {
  @apply uppercase;
}

@media screen and (max-width: 800px) {
  .validateBtn {
    font-size: 12px;
  }
}
</style>
