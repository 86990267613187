<template>
  <div>
    <div v-if="fetching" class="flex justify-center items-center h-[60vh]">
      <spinner class="w-20 h-20" color="oneGreen" />
    </div>
    <div v-else>
      <section class="text-idBlued">
        <h5>SECTION C:</h5>
        <h2>WORK INFORMATION -</h2>
      </section>
      <section class="px-6 py-4 rounded-lg bg-red-100 mt-7 w-full">
        <p class="text-sm font-bold">
          Fill the fields provided below with your correct details
        </p>
        <p class="text-xs font-medium text-dkgray mt-2.5">
          Confirm that the details presented are yours before proceeding to fill
          anything
        </p>
      </section>
      <form @submit.prevent="calcRetirmentAge">
        <div>
          <label for="dateOfPresentConfirmation">
            Date of First Employment
          </label>
          <input
            id="dateOfPresentConfirmation"
            type="date"
            v-model="form1.dateOfFirstEmployment"
            class="input"
          />
        </div>

        <div>
          <label for="firstAssignment">
            Place of First Assignment<span class="req">*</span>
          </label>
          <v-select
            id="firstAssignment"
            label="name"
            :reduce="(b) => b.name"
            :options="$store.state.staff.mdas"
            v-uppercase
            v-model="form1.placeOfFirstAssignment"
            placeholder="-- select --"
          ></v-select>
        </div>
        <div v-if="!showForm2">
          <button
            :disabled="proceeding || !calcRetirmentDateReady"
            type="submit"
          >
            <span>Continue</span>
            <spinner v-if="proceeding"></spinner>
          </button>
        </div>
      </form>
      <div v-if="showForm2">
        <section class="px-6 py-4 rounded-lg bg-red-100 mt-7 w-full">
          <p class="text-sm font-bold">
            Fill the fields provided below with your correct details
          </p>
          <p class="text-xs font-medium text-dkgray mt-2.5">
            All fields must contain your information as at your Retirement. Only
            input your retirement information here.
            <span class="text-[green] font-bold font-mulish"
              >Eg; Input your Grade level as at retirement.</span
            >
          </p>
        </section>
        <form @submit.prevent="proceed">
          <div>
            <label for="retirmentAge"
              >Age at Retirement<span class="req">*</span></label
            >
            <input
              id="retirmentAge"
              type="text"
              v-model="form.ageAtRetirement"
              class="input"
              placeholder="----generated automatically----"
              readonly
            />
          </div>
          <div>
            <label for="dateOfRetirement"
              >Date of Retirement<span class="req">*</span></label
            >
            <input
              id="dateOfRetirement"
              type="text"
              v-model="form.dateOfRetirement"
              class="input"
              placeholder="----generated automatically----"
              readonly
            />
          </div>
          <div>
            <label for="dateOfPresentConfirmation">
              Date of Last Promotion
            </label>
            <input
              id="dateOfPresentConfirmation"
              type="date"
              v-model="form.dateOfLastPromotion"
              class="input"
            />
          </div>
          <div>
            <label for="salaryGradeLevel"
              >Salary Grade Level<span class="req">*</span>
            </label>

            <v-select
              id="salaryGradeLevel"
              label="grade"
              :reduce="(b) => b.grade"
              :options="salaryGradeLevels"
              v-model="form.salaryGradeLevel"
              uid="_id"
              placeholder="-- select --"
            ></v-select>
          </div>
          <div>
            <label for="step">Step<span class="req">*</span> </label>

            <v-select
              id="step"
              label="step"
              :reduce="(b) => b.step"
              :options="steps"
              v-model="form.step"
              uid="_id"
              placeholder="-- select --"
            ></v-select>
          </div>
          <div>
            <label for="departmentCode"
              >Department<span class="req">*</span></label
            >

            <v-select
              id="departmentCode"
              label="departmentName"
              :reduce="(b) => b._id"
              :options="departments"
              v-model="form.departmentId"
              :filterable="false"
              @search="searchDepartments"
              placeholder="-- type here --"
            >
              <template v-slot:no-options="{ search, searching }">
                <template v-if="searching">
                  No results found for <em>{{ search }}</em>
                </template>
                <span v-else clas="text-gray-400 lowercase text-xs">
                  Start typing to search for your department
                </span>
              </template>
            </v-select>
          </div>
          <div>
            <label for="designation"
              >Designation<span class="req">*</span>
            </label>

            <v-select
              id="designation"
              label="designation"
              :reduce="(b) => b.designation"
              :options="$store.state.staff.designations"
              v-model="form.designation"
              uid="_id"
              placeholder="-- select --"
            ></v-select>
          </div>
          <div>
            <label for="lastEmployment">
              Place of Last Employment<span class="req">*</span>
            </label>

            <v-select
              id="lastEmployment"
              label="name"
              :reduce="(b) => b.name"
              :options="$store.state.staff.mdas"
              v-uppercase
              v-model="form.placeOFLastEmployment"
              placeholder="-- select --"
            ></v-select>
          </div>
          <div>
            <label for="pensionZone">
              Pension Zone<span class="req">*</span>
            </label>

            <v-select
              id="pensionZone"
              label="branchName"
              :reduce="(b) => b.branchName"
              :options="pensionZone"
              v-uppercase
              v-model="form.pensionZone"
              placeholder="-- select --"
            ></v-select>
          </div>

          <div v-if="!$store.getters['staff/stepStatus'](3)">
            <button :disabled="proceeding || !formReady" type="submit">
              <span>Save and Proceed</span>
              <spinner v-if="proceeding"></spinner>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import {
  fetchDepartments,
  fetchSalaryGradeLevels,
  fetchGradeSteps,
  fetchPensionZone,
} from "@/services/sourceData";

export default {
  name: "PensionWorkInfo",
  data() {
    return {
      fetching: false,
      proceeding: false,
      dptChanged: false,
      showForm2: false,
      form1: {
        dateOfFirstEmployment: null,
        placeOfFirstAssignment: null,
      },
      form: {
        ageAtRetirement: this.$store.state.staff.pensionInfo.ageAtRetirement,
        dateOfRetirement: this.$store.state.staff.pensionInfo.dateOfRetirement,
        salaryGradeLevel: this.$store.state.staff.pensionInfo.salaryGradeLevel,
        designation: this.$store.state.staff.pensionInfo.designation,
        step: this.$store.state.staff.pensionInfo.step,
        placeOFLastEmployment:
          this.$store.state.staff.pensionInfo.placeOFLastEmployment,
        departmentId: this.$store.state.staff.pensionInfo.departmentId,
        dateOfLastPromotion:
          this.$store.state.staff.pensionInfo.dateOfLastPromotion,
        pensionZone: this.$store.state.staff.pensionInfo.pensionZone,
      },
      departments: [],
      salaryGradeLevels: [],
      steps: [],
      pensionZone: [],
      requiredForm: [
        "ageAtRetirement",
        "dateOfRetirement",
        "salaryGradeLevel",
        "step",
        "designation",
        "placeOFLastEmployment",
        "departmentId",
        "dateOfLastPromotion",
        "pensionZone",
      ],
    };
  },
  computed: {
    calcRetirmentDateReady() {
      return (
        this.form1.dateOfFirstEmployment && this.form1.placeOfFirstAssignment
      );
    },
    formReady() {
      let ready = true;
      for (const fd of this.requiredForm) {
        if (this.form[fd] === null || this.form[fd] === "") {
          ready = false;
        }
      }
      return ready;
    },
  },
  beforeMount() {
    this.fetchSalaryGradeLevel();
    this.fetchSteps();
    this.fetchPensionZones();
  },
  methods: {
    async searchDepartments(search, loading) {
      if (search.length < 3) {
        return;
      }

      try {
        loading(true);
        this.departments = await fetchDepartments(search);
        loading(false);
      } catch (err) {
        loading(false);
      }
    },
    formatDate(dt) {
      return !dt ? dt : new Date(dt).toISOString().substring(0, 10);
    },
    async fetchSteps() {
      this.steps = await fetchGradeSteps();
    },
    async calcRetirmentAge() {
      if (!this.calcRetirmentDateReady) {
        this.$swal({
          icon: "error",
          text: "Fill all fiields",
        });
        return;
      }
      try {
        this.proceeding = true;

        const res = await this.$http.post(
          "/pensionier/calc-retirement",
          this.form1
        );

        this.proceeding = false;
        if (!res) {
          return;
        }
        const { data } = res;

        this.form.ageAtRetirement = data.data.ageAtRetirement;
        this.form.dateOfRetirement = this.formatDate(
          data.data.dateOfRetirement
        );
        this.showForm2 = true;
      } catch (error) {
        this.proceeding = false;
        console.log({ error });
      }
    },
    async proceed() {
      if (!this.formReady) {
        this.$swal({
          icon: "error",
          text: "Fill all fiields",
        });
        return;
      }

      try {
        this.proceeding = true;
        this.form.dateOfFirstEmployment = this.form1.dateOfFirstEmployment;
        this.form.placeOfFirstAssignment = this.form1.placeOfFirstAssignment;
        const res = await this.$http.post("/pensionier/work-info", this.form);

        this.proceeding = false;
        if (!res) {
          return;
        }

        const { data } = res;

        this.$store.commit("staff/saveData", {
          data: data.data,
          key: "pensionInfo",
        });
        this.$store.commit("staff/updateVerifyStep", 4);
        this.$store.commit("staff/updateBStep", 3);

        this.$router.push({ name: "Pension Section D" });
      } catch (error) {
        console.log({ error });
        this.proceeding = false;
      }
    },
    async fetchSalaryGradeLevel() {
      this.salaryGradeLevels = await fetchSalaryGradeLevels();
    },
    async fetchPensionZones() {
      this.pensionZone = await fetchPensionZone();
    },
  },
};
</script>

<style scoped>
h5 {
  @apply text-xs lg:text-base font-bold uppercase;
}
h2 {
  @apply text-xl lg:text-3pxl uppercase;
}
form {
  @apply bg-litergray rounded-lg p-6 mt-7;
}
form > div:not(:first-child) {
  @apply mt-6;
}
.req {
  @apply text-red-400;
}
form button[type="submit"] {
  @apply w-full bg-oneGreen text-white font-semibold py-2.5 rounded-lg flex justify-center items-center gap-2;
}
input {
  @apply uppercase;
}
</style>
